export class KeyBehaviorBlocker {

    private keyListener: (e: KeyboardEvent) => void;
    private hasBeenSet: boolean = false;

    initCustomKeyBlocker(keyCodesToBlock: Array<number>) {
        if (!this.hasBeenSet) {
            this.keyListener = (e: KeyboardEvent) => this.blockKeys(e, keyCodesToBlock)
            this.hasBeenSet = true;
        }
        document.addEventListener('keydown', this.keyListener, true);
    }

    disposeCustomKeyBlocker() {
        document.removeEventListener('keydown', this.keyListener, true);
        this.hasBeenSet = false;
    }

    blockKeys(e: KeyboardEvent, blockedKeyCodes: Array<number>): void {
        if (blockedKeyCodes.includes(e.keyCode)) {
            e.preventDefault();
        }
    }
}
