export default class CopyTableToClipboardHelper {
    async copyTable(el: HTMLElement, organization: string, title: string){
        const tableHeaders = el.querySelector("thead") as HTMLTableSectionElement;
        const tableBody = el.querySelector("tbody") as HTMLTableSectionElement;
        let values = [];

        // Writing the report metadata
        values.push(organization);
        values.push(title);
        values.push("Date Created: " + new Date().toLocaleDateString("en-US", {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        }));

        // Writing the headers
        const headerValues = [""];
        Array.from(tableHeaders.children).map(th => headerValues.push(th.querySelector('input') ? 'Contact' : th.textContent.trim()));
        values.push(headerValues.join("\t"));

        // Writing the table body/rows
        for(const row of tableBody.children){
            const rowValues = [""];
            Array.from(row.children).map((td : HTMLTableCellElement) => {
                const input = td.querySelector('input');
                rowValues.push(input ? input.value :td.innerText);
            });
            values.push(rowValues.join("\t"));
        }
        await navigator.clipboard.writeText(values.join("\n"));
    }
}
