import posthog from "posthog-js";

export default class ClientsidePostHog {
    init(phApiKey: string, phHost: string, userDistinctId: string) {
        posthog.init(phApiKey, {
            api_host: 'https://' + phHost,
            autocapture: false,
            capture_pageview: false,
            capture_pageleave: false,
            secure_cookie: true
        });
        posthog.identify(userDistinctId);
    }
    reset() {
        posthog.reset();
    }
}
