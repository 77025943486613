export class NavLock {
    public setNavLock() {
        window.addEventListener("beforeunload", this.navFunction)
    }
    public removeNavLock() {
        window.removeEventListener("beforeunload", this.navFunction)
    }

    private navFunction = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
    }
}
