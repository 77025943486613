export default class ScrollByCell {
    initScrollHandler(el: HTMLDivElement) {
        //------------------------------------------------------------ FUNCTIONS ------------------------------------------------------------//
        function wheelListener(e: WheelEvent){
            //this ignores horizontal laptop trackpad movements
            if (Math.abs(e.deltaY) < 10) {
                return;
            }
            e.preventDefault();
            if(e.shiftKey){
                heatgridBody.scrollBy({
                    left: e.deltaY,
                })
            } else {
                heatgridBody.scrollBy({
                    top: e.deltaY,
                })
            }
        }

        function mouseOverCellListener(e: Event) {
            const target = e.target as HTMLElement;
            const rowColString = target.dataset.rowCol;
            if (!rowColString) {
                return;
            }
            const [rowIndexString, colIndexString] = rowColString.split("-");
            if (!rowIndexString || !colIndexString) {
                return;
            }

            const rowIndex = Number(rowIndexString);
            const colIndex = Number(colIndexString);

            if (isNaN(rowIndex) || isNaN(colIndex)) {
                return;
            }

            rowLabelsContainer.children[rowIndex].classList.add("hovered");
            // first child is an empty div for spacing
            columnLabelsContainer.children[colIndex+1].children[0].classList.add("hovered");
        }

        function mouseOutCellListener(e: Event) {
            const target = e.target as HTMLElement;
            const rowColString = target.dataset.rowCol;
            if (!rowColString) {
                return;
            }
            const [rowIndexString, colIndexString] = rowColString.split("-");
            if (!rowIndexString || !colIndexString) {
                return;
            }

            const rowIndex = Number(rowIndexString);
            const colIndex = Number(colIndexString);

            if (isNaN(rowIndex) || isNaN(colIndex)) {
                return;
            }

            rowLabelsContainer.children[rowIndex].classList.remove("hovered");
            // first child is an empty div for spacing
            columnLabelsContainer.children[colIndex+1].children[0].classList.remove("hovered");
        }

        function hideRowAndColLabels() {
            // Calculate which rows & cols are showing then show/hide corresponding labels.

            const firstRowShown = Math.round(heatgridBody.scrollTop / rowHeight);

            if (startingShownRow !== null) {
                // Hide the old ones.
                for (let i = startingShownRow; i < Math.min(startingShownRow + numRowsToDisplay, rowLabelsContainer.children.length); i++) {
                    (rowLabelsContainer.children[i] as HTMLElement).hidden = true;
                }
            } else {
                // Initial load, hide first shown labels.
                for (let i = 0; i < firstRowShown /*No need to hide just to un-hide later.*/; i++) {
                    const rowLabel = rowLabelsContainer.children[i] as HTMLElement;
                    // If it's hidden already, we must have exhausted the initially shown labels.
                    if (rowLabel.hidden) {
                        break;
                    }
                    rowLabel.hidden = true;
                }
            }
            // Show the new ones (or, when we're initializing, un-hide the initially shown).
            for (let i = firstRowShown; i < Math.min(firstRowShown + numRowsToDisplay, rowLabelsContainer.children.length); i++) {
                (rowLabelsContainer.children[i] as HTMLElement).hidden = false;
            }
            startingShownRow = firstRowShown;

            const firstColShown = Math.round(heatgridBody.scrollLeft / columnWidth);

            // There's an empty div to start, so we have a bunch of off-by-ones.
            if (startingShownCol !== null) {
                // Hide the old ones.
                for (let i = startingShownCol; i < Math.min(startingShownCol + numColsToDisplay, columnLabelsContainer.children.length); i++) {
                    (columnLabelsContainer.children[i+1] as HTMLElement).hidden = true;
                }
            } else {
                // Initial load, hide first shown labels.
                for (let i = 0; i < firstColShown /*No need to hide just to un-hide later.*/; i++) {
                    const rowLabel = columnLabelsContainer.children[i+1] as HTMLElement;
                    // If it's hidden already, we must have exhausted the initially shown labels.
                    if (rowLabel.hidden) {
                        break;
                    }
                    rowLabel.hidden = true;
                }
            }
            // Show the new ones (or, when we're initializing, un-hide the initially shown)
            for (let i = firstColShown; i < Math.min(firstColShown + numColsToDisplay, columnLabelsContainer.children.length); i++) {
                (columnLabelsContainer.children[i+1] as HTMLElement).hidden = false;
            }

            startingShownCol = firstColShown;
        }


        //------------------------------------------------------------ Code to Run on Page Load ------------------------------------------------------------//
        const scrollbarWidth = (() =>
        {
            // Adapted from https://davidwalsh.name/detect-scrollbar-width.
            // If you, dear reader, are wanting to steal this, maybe you should put it in index.ts instead.

            // Create a temporary element
            const scrollDiv = document.createElement('div');

            // Set the style to ensure the element is scrollable
            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.overflow = 'scroll';
            scrollDiv.style.position = 'absolute';
            scrollDiv.style.top = '-9999px';

            document.body.appendChild(scrollDiv);

            // Calculate scrollbar width
            const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

            document.body.removeChild(scrollDiv);

            return scrollbarWidth;
        })();

        let startingShownRow: number | null = null;
        let startingShownCol: number | null = null;
        const heatgridBody = el.querySelector('.heatgrid-body') as HTMLDivElement
        const columnWidth = (heatgridBody.children[0].children[0] as HTMLElement).offsetWidth  + parseInt(getComputedStyle(heatgridBody.children[0]).columnGap.slice(0, -2));
        const rowHeight   = (heatgridBody.children[0].children[0] as HTMLElement).offsetHeight + parseInt(getComputedStyle(heatgridBody).rowGap.slice(0, -2));
        const rowLabelsContainer = el.querySelector('.heatgrid-row-labels');
        const numColumnsThatWillFit = Math.floor(heatgridBody.offsetWidth / columnWidth);
        const numRowsThatWillFit = Math.floor(heatgridBody.offsetHeight / rowHeight);
        const numColsToDisplay = Math.min(numColumnsThatWillFit, heatgridBody.children[0].children.length);
        const numRowsToDisplay = Math.min(numRowsThatWillFit, heatgridBody.children.length)

        //If there are fewer columns than there are spaces available, hide overflow x
        if (numColsToDisplay >= heatgridBody.children[0].children.length) {
            heatgridBody.style.overflowX = 'hidden';
            heatgridBody.style.height = numRowsToDisplay * rowHeight + "px";
        } else { //otherwise there will be a bottom scrollbar and we need to increase the height of our heatgrid to accomodate
            heatgridBody.style.height = numRowsToDisplay * rowHeight + scrollbarWidth +  "px";
        }
        if (numRowsToDisplay >= heatgridBody.children.length) {
            heatgridBody.style.overflowY = 'hidden';
            heatgridBody.style.width = numColsToDisplay * columnWidth + "px";
        } else {
            heatgridBody.style.width = numColsToDisplay * columnWidth + scrollbarWidth + "px";
        }

        //set the row title height equal to the height of the table
        const rowTitle = el.querySelector('#heatgrid-row-label') as HTMLElement;
        rowTitle.style.height = heatgridBody.style.height;
        const columnLabelsContainer = el.querySelector('.heatgrid-column-labels') as HTMLDivElement;
        columnLabelsContainer.parentElement.style.width = parseInt(heatgridBody.style.width.slice(0, -2)) + 140 + "px";
        hideRowAndColLabels();


        //------------------------------------------------------------ Set Event Listeners ------------------------------------------------------------//
        for (const row of heatgridBody.children) {
            for (const cell of row.children) {
                cell.addEventListener('mouseover', mouseOverCellListener);
                cell.addEventListener("mouseout", mouseOutCellListener);
            }
        }
        heatgridBody.addEventListener('wheel', wheelListener);
        heatgridBody.addEventListener('scroll', hideRowAndColLabels);
        return {
            destroy: () => {
                heatgridBody.removeEventListener('scroll', hideRowAndColLabels);
                heatgridBody.removeEventListener('wheel', wheelListener);
            }
        }
    }
}
