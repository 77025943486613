import {DotNetObjectReference} from "./DotnetObjectReference";

export default class InfiniteScrolling {
    dispose(observer: IntersectionObserver) {
        observer.disconnect();
    }

    findClosestScrollContainer(element: Element) {
        while (element) {
            const style = getComputedStyle(element);
            if (style.overflowY !== 'visible') {
                return element;
            }
            element = element.parentElement;
        }
        return null;
    }
    initInfiniteScrolling(lastItemIndicator: Element, componentInstance: DotNetObjectReference) {
        const scrollContainer = this.findClosestScrollContainer(lastItemIndicator);

        const options = {
            root: scrollContainer,
            rootMargin: '0px',
            threshold: 0,
        };
        const observer: IntersectionObserver = new IntersectionObserver(async (entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    observer.unobserve(lastItemIndicator);
                    await componentInstance.invokeMethodAsync("LoadMoreItems");
                }
            }
        }, options);
        observer.observe(lastItemIndicator);

        return {
            dispose: () => this.dispose(observer),
            onNewItems: () => {
                observer.unobserve(lastItemIndicator);
                observer.observe(lastItemIndicator);
            },
        };
    }
}
