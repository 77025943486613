export class ActivityDetector {
    private activityCheckTimer: NodeJS.Timeout;
    private abortController: AbortController | null = null;
    private ignoreMouseEvents: boolean = false;
    /*
        Initializes user activity detection that pings /api/activity/ping immediately and then when the user is active
        after at least pingIntervalMillis have elapsed.
     */
    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    initActivityDetection(token: string) {
        const t = this;

        const onActivityDetectedAndMouseEventsAllowed = () => {
            if (!t.ignoreMouseEvents) {
                onActivityDetected();
            }
        }

        const onClick = (event: MouseEvent) => {
            const clickedElement = event.target as HTMLElement | null;
            const elementId = clickedElement.id;
            if (elementId === "ignore-inactive-continue" || elementId === "ignore-inactive-exit") {
                onActivityDetected();
            } else {
                onActivityDetectedAndMouseEventsAllowed();
            }
        }

        function setActivityCheck() {
            t.abortController = new AbortController();
            const listenerOptions: AddEventListenerOptions = {
                signal: t.abortController.signal, //Remove this listener if the others fire
                passive: true, //Don't wait for this handler to finish before allowing this event to do other things
            };
            window.addEventListener('pointermove', onActivityDetectedAndMouseEventsAllowed, listenerOptions);
            window.addEventListener('mousemove', onActivityDetectedAndMouseEventsAllowed, listenerOptions);
            window.addEventListener('click', onClick, listenerOptions); // This is intentional to allow click on modal to be detected
            window.addEventListener('keydown', onActivityDetectedAndMouseEventsAllowed, listenerOptions);
            window.addEventListener('scroll', onActivityDetectedAndMouseEventsAllowed, listenerOptions);
        }

        function onActivityDetected() {
            t.abortController?.abort();
            clearTimeout(t.activityCheckTimer);
            fetch("/api/activity/ping", {
                method: "POST",
                headers: {
                    'Require-Preflight': "true", // This is a custom header that we include to ensure a CORS Preflight request is sent. This helps guard against CSRF attacks and be read about here: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests.
                    RequestVerificationToken: token,
                }
            })
                .then(r => r.json())
                .then(timeUntilNextPingSeconds => {
                    t.activityCheckTimer = setTimeout(setActivityCheck, timeUntilNextPingSeconds * 1000);
                })
        }

        onActivityDetected();
    }

    /*
        Stops activity detection by aborting the listeners and clearing the interval timeout
     */
    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    clearActivityDetection() {
        this.abortController?.abort();
        clearTimeout(this.activityCheckTimer);
    }

    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    disableMouseEvents() {
        this.ignoreMouseEvents = true;
    }

    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    enableMouseEvents() {
        this.ignoreMouseEvents = false;
    }
}
