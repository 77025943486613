import Chart, {ChartConfiguration} from 'chart.js/auto';

export default class Charts {
    initializeChart(canvasElement: HTMLCanvasElement, chartData: ChartConfiguration, isCurrency: boolean): object | null {
        if (!canvasElement || canvasElement.tagName !== "CANVAS") {
            throw new Error(`Canvas element with id '${canvasElement?.id}' not found`);
        }

        if (isCurrency) {
            chartData.options.scales.y.ticks.callback = function (value: number): string { return '$' + value.toLocaleString(); };
            chartData.options.plugins.tooltip.callbacks.label = Charts.formatCurrencyForTooltip
        }
        let chart = new Chart(canvasElement, chartData);
        return {
            destroy: () => {
                Charts.destroyChart(chart);
            }
        }
    };

    // Destroy the chart instance if it exists
    static destroyChart(chartInstance: Chart | null): void {
        if (chartInstance) {
            chartInstance.destroy();
        } else {
            console.warn("Attempted to destroy a null or undefined chart instance.")
        }
    }

    static formatCurrencyForTooltip(context: TooltipContext): string {
        // @ts-ignore
        let label = context.dataset.label || '';

        if (label) {
            label += ': ';
        }
        // @ts-ignore
        if (context.parsed.y != null) {
            // @ts-ignore
            label += new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(context.parsed.y);
        }
        return label;
    }

}

// This class implements the interface that is passed into 'formatCurrencyForTooltip'. That interface contains more members,
// but this implementation only includes what is being used in the 'formatCurrencyForTooltip' function. The full interface
// can be seen at https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-item-context
export class TooltipContext {
    // Parsed data values for the given `dataIndex` and `datasetIndex`
    parsed: object
    // The dataset the item comes from
    dataset: object
}
