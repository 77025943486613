import interact from 'interactjs'
import {DotNetObjectReference} from "./DotnetObjectReference";

type CoordinateData = {
    x: number, y: number, index: number
}

function getTopLeftCorner(element: HTMLElement, ind: number = 0): CoordinateData {
    const rect = element.getBoundingClientRect();
    return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        index: ind
    };
}

function getChildrenCoords(element: HTMLElement): CoordinateData[] {
    return Array.from(element.children).map(getTopLeftCorner);
}

function findClosest(pair: CoordinateData, pairs: CoordinateData[]): number {
    return pairs.reduce(({ sqDistance, index }, currentPair) => {
        const newSqDistance = Math.pow(Math.abs(currentPair.x - pair.x), 2) +
            Math.pow(Math.abs(currentPair.y - pair.y), 2);
        if (newSqDistance < sqDistance) {
            return { sqDistance: newSqDistance, index: currentPair.index }
        }
        else return { sqDistance, index }
    }, { sqDistance: Number.POSITIVE_INFINITY, index: -1 }).index
}

type SortableContext = {
    update: () => void;
}

function initDraggable(element: HTMLElement, listKey: string, showCount: boolean, showCountClass: string, pageScrollId: string, onSelect: (key: string) => void, onShiftSelect: (key: string) => void, onCtrlSelect: (key: string) => void) {
    element.dataset.dndListKey = listKey;
    const position = { x: 0, y: 0 }

    const render = () => {
        element.style.transform =
            `translate(${position.x}px, ${position.y}px)`
    }

    interact(element).on('tap', function(event: Interact.InteractEvent) {
        let key = element.dataset.dndKey;

        // right click gives unwanted behavior here so we'll return early
        if (event.button === 2) return;

        if (event.shiftKey) {
            // Action if Shift key is pressed
            onShiftSelect(key)
        } else if (event.ctrlKey) {
            // Action if Control key is pressed
            onCtrlSelect(key);
        } else {
            // Action if no key is pressed
            onSelect(key)
        }
    });

    let countElement: HTMLElement | null = null;
    function makeCountElement(count: number) {
        const countDiv: HTMLDivElement = document.createElement('div');

        countDiv.textContent = count + " rows";
        countDiv.id = 'count-element';
        let itemClass: string = 'absolute -top-10 -left-10 ' + showCountClass;
        countDiv.setAttribute('class', itemClass);
        countDiv.style.userSelect = 'none';
        countDiv.style.zIndex = '9100';
        return countDiv;
    }

    function updateMouseFollowPosition(event: MouseEvent) {
        if (countElement != null) {
            const elementWidth = countElement.offsetWidth;
            const elementHeight = countElement.offsetHeight;

            const maxX = window.innerWidth - elementWidth;
            const maxY = window.innerHeight - elementHeight;

            // To prevent count element clipping off the screen and causing styling issues
            const x = Math.min(maxX, Math.max(0, event.pageX + 10));
            const y = Math.min(maxY, Math.max(0, event.pageY + 10));

            countElement.style.left = `${x}px`;
            countElement.style.top = `${y}px`;
        }
    }

    function handleAutoScroll(scrollingElement: HTMLElement) {
        if (!scrollingElement) return;
        const rect = scrollingElement.getBoundingClientRect();

        // if 125px from bottom, then scroll down
        let isNearBottom = position.y > rect.bottom - 125;
        if (isNearBottom) {
            scrollingElement.scrollBy({
                top: 40,
                behavior: 'smooth'
            });
            return;
        }

        // if 125px from top, then scroll up
        let isNearTop = position.y < rect.top + 125;
        if (isNearTop) {
            scrollingElement.scrollBy({
                top: -40,
                behavior: 'smooth'
            });
        }
    }

    let autoScrollInterval: number | null = null;
    const scrollingPageDiv = document.getElementById(pageScrollId);

    interact(element).draggable({
        allowFrom: element.querySelector('[data-dnd-draghandle]') ? '[data-dnd-draghandle]' : undefined,
        listeners: {
            start (event) {
                const { target: draggableElement } = event;
                const rect = element.getBoundingClientRect();
                draggableElement.dataset.dndDraggingActive = true;
                element.style.position = 'fixed';
                element.style.zIndex = '9000';
                element.style.pointerEvents = 'none'
                element.style.top = '0';
                element.style.left = '0';
                position.x = rect.left;
                position.y = rect.top;

                let selectedList = element.dataset.dndSelected
                let count = selectedList.split(',').length; // gets count of items in comma seperated list
                if (count > 1 && showCount) {
                    countElement = makeCountElement(count);
                    document.body.appendChild(countElement);
                    document.addEventListener('mousemove', updateMouseFollowPosition);
                }
                render();

                // Continually check if page should auto scroll
                autoScrollInterval = window.setInterval(() => {
                    handleAutoScroll(scrollingPageDiv);
                }, 100); // Check every 100ms
            },
            move (event) {
                position.x += event.dx
                position.y += event.dy
                render();
            },
            end (event) {
                document.removeEventListener('mousemove', updateMouseFollowPosition);
                if (countElement != null){
                    document.body.removeChild(countElement);
                }

                if (autoScrollInterval !== null) {
                    window.clearInterval(autoScrollInterval);
                    autoScrollInterval = null;
                }

                position.x = 0;
                position.y = 0;
                element.removeAttribute('style');
                render()

                delete event.target.dataset.dndDraggingActive;
                delete event.target.dataset.dndOverDropZone;
            }
        }
    })
}

export default class Sortable {
    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    init(dropzone: HTMLElement, dropzoneOverride: HTMLElement | null, pageScrollId: string, listKey: string, group: string, draggableClass: string, showCountClass: string, showCount: boolean, listeners: DotNetObjectReference): SortableContext {
        let childCoords: CoordinateData[] = [];
        const recomputeCoords = () => {
            childCoords = getChildrenCoords(dropzone);
        }
        dropzone.dataset.dndDraggableClass = draggableClass;

        const initDraggables = () => {
            Array.from(dropzone.children)
                .map(e => (e as HTMLElement))
                .filter(e => e.dataset.dndGroup && (e.dataset.dndListKey !== listKey))
                .map(e => initDraggable(
                    e as HTMLElement,
                    listKey,
                    showCount,
                    showCountClass,
                    pageScrollId,
                    k => listeners.invokeMethodAsync("OnItemSelectedCallback", k),
                    k => listeners.invokeMethodAsync("OnItemShiftSelectedCallback", k),
                    k => listeners.invokeMethodAsync("OnItemCtrlSelectedCallback", k)
                    )
                );
        }

        initDraggables();

        let placeholder: HTMLElement | null = null;

        dropzone.dataset.dndListKey = listKey;
        const renderPlaceholder = (index: number, factory: () => HTMLElement) => {
            if (placeholder?.parentNode === dropzone) {
                dropzone.removeChild(placeholder);
            }
            else {
                placeholder = factory();
                placeholder.removeAttribute('style');
                placeholder.setAttribute('class', dropzone.dataset.dndDraggableClass);
                placeholder.style.opacity = "0.5";
                placeholder.style.transform = '';
                delete placeholder.dataset.dndDraggingActive;
                placeholder.dataset.dndPlaceholder = 'true';
            }

            if (index >= dropzone.children.length) {
                dropzone.appendChild(placeholder);
            } else {
                const referenceChild = dropzone.children[index];
                dropzone.insertBefore(placeholder, referenceChild);
            }
        }

        const clearPlaceholder = () => {
            placeholder?.remove();
            placeholder = null;
        }

        dropzone.dataset.dndGroup = group;

        let dropzoneElement: HTMLElement = dropzone;
        if (dropzoneOverride) dropzoneElement = dropzoneOverride;

        const scrollableDiv = document.getElementById(pageScrollId);
        // required for a scrolling page but also resource intensive so only use when we need it
        if (scrollableDiv) interact.dynamicDrop(true);

        interact(dropzoneElement).dropzone({
            accept: `[data-dnd-group=${group}]`,//Only accept draggables from same group
            overlap: 'pointer',
            ondropactivate: function (event) {
                // add active dropzone feedback
                event.target.dataset.dndDropActive = true;
                recomputeCoords();

                // Keeps childCoords accurate during scroll
                scrollableDiv?.addEventListener("scroll", recomputeCoords);
            },
            ondragenter: function (event) {
                const { relatedTarget: draggableElement } = event;

                // feedback the possibility of a drop
                dropzone.dataset.dndDropTarget = 'true';
                draggableElement.dataset.dndOverDropZone = true;

                // fire enter event
                const { dndKey, dndGroup } = draggableElement.dataset;
                if (dndGroup === dropzone.dataset.dndGroup) {
                    const _ = listeners.invokeMethodAsync("OnEnterCallback", dndKey);
                }
            },
            ondropmove: function (event) {
                const { relatedTarget: draggableElement } = event;
                const closestIndex = findClosest(getTopLeftCorner(draggableElement), childCoords);
                draggableElement.dataset.dndSortableIndex = "" + closestIndex;
                renderPlaceholder(closestIndex, () => draggableElement.cloneNode(true));
            },
            ondragleave: function (event) {
                const { relatedTarget: draggableElement } = event;

                // remove the drop feedback style
                delete dropzone.dataset.dndDropTarget;
                delete draggableElement.dataset.dndOverDropZone;
                clearPlaceholder();
            },
            ondrop: function (event) {
                const { relatedTarget: draggableElement } = event;

                const { dndKey, dndSelected, dndGroup, dndSortableIndex, dndListKey } = draggableElement.dataset;
                if (dndGroup === dropzone.dataset.dndGroup) {
                    if (dndListKey === dropzone.dataset.dndListKey) {
                        //Moving within the same list
                        const _ = listeners.invokeMethodAsync("OnItemMovedCallback", dndKey, dndSelected, +dndSortableIndex);
                    }
                    else {
                        //Moving from another list
                        listeners.invokeMethodAsync("OnItemRemovedCallback", dndKey, dndSelected);
                        listeners.invokeMethodAsync("OnItemAddedCallback", dndKey, dndSelected, +dndSortableIndex);
                        if (!dropzone.contains(draggableElement)) draggableElement.classList.add('hidden');
                    }
                    clearPlaceholder();
                }
            },
            ondropdeactivate: function (event) {
                // remove active dropzone feedback
                delete event.target.dataset.dndDropActive;
                delete event.target.dataset.dndDropTarget;
                scrollableDiv?.removeEventListener("scroll", recomputeCoords);
            }
        });
        return {
            //Something something poor-man's objects
            update: () => {
                recomputeCoords();
                initDraggables();
            }
        };
    }
}
